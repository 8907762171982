/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  useRouteError
} from 'react-router-dom';
import { BaseLayout } from '@triplabo/web/layouts';

const Dashboard = lazy(() => import('@triplabo/web/views/Dashboard'));
const Home = lazy(() => import('@triplabo/web/views/Home'));
const RideOn = lazy(() => import('@triplabo/web/views/RideOn'));
const RideOff = lazy(() => import('@triplabo/web/views/RideOff'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={lazyView(<Dashboard />)} />
          <Route path="home" element={lazyView(<Home />)} />
          <Route path="rideon" element={lazyView(<RideOn />)} />
          <Route path="rideoff" element={lazyView(<RideOff />)} />
        </Route>
      </Route>

      <Route path="*" element={lazyView(<Dashboard />)} />
    </Route>
  )
);

export default router;
