import styles from './header.module.css';

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <a href="/" className={styles.logo}>
          Taxi
        </a>
      </div>
    </header>
  );
};

export default Header;
